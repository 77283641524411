import { produce } from 'immer';

import { getApplications, createApplication, editApplication, deleteApplication, selectedApplication } from './actions';

export const initialState = {
  applications: {
    loading: false,
    loaded: false,
    error: null,
    list: [],
  },

  createApplication: {
    loading: false,
    loaded: false,
    error: null,
  },

  editApplication: {
    loading: false,
    loaded: false,
    error: null,
  },

  deleteApplication: {
    loading: false,
    loaded: false,
    error: null,
  },

  selectedApplication: {
    error: null,
    app: '',
  },
};

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getApplications.REQUEST:
        draft.applications.loading = true;
        draft.applications.loaded = false;
        draft.applications.error = null;
        break;

      case getApplications.SUCCESS:
        draft.applications.loading = false;
        draft.applications.loaded = true;
        draft.applications.error = null;
        draft.applications.list = payload;
        break;

      case getApplications.FAILURE:
        draft.applications.loading = false;
        draft.applications.loaded = false;
        draft.applications.error = payload;
        break;

      case createApplication.REQUEST:
        draft.createApplication.loading = true;
        draft.createApplication.loaded = false;
        draft.createApplication.error = null;
        break;

      case createApplication.SUCCESS:
        draft.createApplication.loading = false;
        draft.createApplication.loaded = true;
        draft.createApplication.error = null;
        break;

      case createApplication.FAILURE:
        draft.createApplication.loading = false;
        draft.createApplication.loaded = false;
        draft.createApplication.error = payload;
        break;

      case createApplication.RESET:
        draft.createApplication = { ...initialState.createApplication };
        break;

      case editApplication.REQUEST:
        draft.editApplication.loading = true;
        draft.editApplication.loaded = false;
        draft.editApplication.error = null;
        break;

      case editApplication.SUCCESS:
        draft.editApplication.loading = false;
        draft.editApplication.loaded = true;
        draft.editApplication.error = null;
        break;

      case editApplication.FAILURE:
        draft.editApplication.loading = false;
        draft.editApplication.loaded = false;
        draft.editApplication.error = payload;
        break;

      case editApplication.RESET:
        draft.editApplication = { ...initialState.editApplication };
        break;

      case deleteApplication.REQUEST:
        draft.deleteApplication.loading = true;
        draft.deleteApplication.loaded = false;
        draft.deleteApplication.error = null;
        break;

      case deleteApplication.SUCCESS:
        draft.deleteApplication.loading = false;
        draft.deleteApplication.loaded = true;
        draft.deleteApplication.error = null;
        break;

      case deleteApplication.FAILURE:
        draft.deleteApplication.loading = false;
        draft.deleteApplication.loaded = false;
        draft.deleteApplication.error = payload;
        break;

      case deleteApplication.RESET:
        draft.deleteApplication = { ...initialState.deleteApplication };
        break;

      case selectedApplication.SUCCESS:
        draft.selectedApplication.error = false;
        draft.selectedApplication.app = payload;
        break;

      case selectedApplication.FAILURE:
        draft.selectedApplication.error = payload;
        break;

      case selectedApplication.RESET:
        draft.selectedApplication = { ...initialState.selectedApplication };
        break;

      default:
        return draft;
    }
  });

export default reducer;
