/* eslint-disable no-useless-escape */
export const onlyLettersRegex = /^[A-Za-zÁÉÍÓÚáéíóúñÑ_ ]+$/;
export const onlyAlphanumericRegex = /^[A-Za-zÁÉÍÓÚáéíóúñÑ0-9]+$/;
export const propertyObjectRegex = /^\w+$/;
export const versionRegex = /^(\d+\.)?(\d+\.)?(\*|\d+)$/;
export const nameRegex = /^[ñÑA-Za-z _]*[ñÑA-Za-z][ñÑA-Za-z _]*$/;
export const nameRegexWithDot = /^[ñÑA-Za-z _]*[ñÑA-Za-z][ñÑA-Za-z _ .]*$/;
export const UUIDRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
