import { produce } from 'immer';

import { getRoles, createRole, editRole, deleteRole } from './actions';

export const initialState = {
  roles: {
    loading: false,
    loaded: false,
    error: null,
    list: [],
  },

  createRole: {
    loading: false,
    loaded: false,
    error: null,
  },

  editRole: {
    loading: false,
    loaded: false,
    error: null,
  },

  deleteRole: {
    loading: false,
    loaded: false,
    error: null,
  },
};

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getRoles.REQUEST:
        draft.roles.loading = true;
        draft.roles.loaded = false;
        draft.roles.error = null;
        break;

      case getRoles.SUCCESS:
        draft.roles.loading = false;
        draft.roles.loaded = true;
        draft.roles.error = null;
        draft.roles.list = payload;
        break;

      case getRoles.FAILURE:
        draft.roles.loading = false;
        draft.roles.loaded = false;
        draft.roles.error = payload;
        break;

      case createRole.REQUEST:
        draft.createRole.loading = true;
        draft.createRole.loaded = false;
        draft.createRole.error = null;
        break;

      case createRole.SUCCESS:
        draft.createRole.loading = false;
        draft.createRole.loaded = true;
        draft.createRole.error = null;
        break;

      case createRole.FAILURE:
        draft.createRole.loading = false;
        draft.createRole.loaded = false;
        draft.createRole.error = payload;
        break;

      case createRole.RESET:
        draft.createRole = { ...initialState.createRole };
        break;

      case editRole.REQUEST:
        draft.editRole.loading = true;
        draft.editRole.loaded = false;
        draft.editRole.error = null;
        break;

      case editRole.SUCCESS:
        draft.editRole.loading = false;
        draft.editRole.loaded = true;
        draft.editRole.error = null;
        break;

      case editRole.FAILURE:
        draft.editRole.loading = false;
        draft.editRole.loaded = false;
        draft.editRole.error = payload;
        break;

      case editRole.RESET:
        draft.editRole = { ...initialState.editRole };
        break;

      case deleteRole.REQUEST:
        draft.deleteRole.loading = true;
        draft.deleteRole.loaded = false;
        draft.deleteRole.error = null;
        break;

      case deleteRole.SUCCESS:
        draft.deleteRole.loading = false;
        draft.deleteRole.loaded = true;
        draft.deleteRole.error = null;
        break;

      case deleteRole.FAILURE:
        draft.deleteRole.loading = false;
        draft.deleteRole.loaded = false;
        draft.deleteRole.error = payload;
        break;

      case deleteRole.RESET:
        draft.deleteRole = { ...initialState.deleteRole };
        break;

      default:
        return draft;
    }
  });

export default reducer;
