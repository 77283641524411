import { useEffect } from 'react';
import { signOut } from 'utils/auth';

function SignOut() {
  useEffect(() => {
    signOut();
  });

  return null;
}

export default SignOut;
