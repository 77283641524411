const configDefaultCognito = {
  token: '/oauth2/token',
  authorize: '/oauth2/authorize?',
  logout: '/logout?',
  grantType: 'authorization_code',
  grantTypeRefreshToken: 'refresh_token',
  login: '/login?',
};

const environments = {
  LOCAL: {
    apiBase: 'https://api.rbp.dev-gbmapi.com',
    apiBasePatron: 'https://backoffice.auth.dev-gbmapi.com',
    rolloutKey: '6079adf812147765a02a3ab1',
    torahDocumentation: 'https://torah.stg-gbmapi.com',
    configDefaultCognito: {
      ...configDefaultCognito,
      authUrl: 'https://patron-backoffice-stg-1596325.auth.us-east-1.amazoncognito.com',
      authRedirectLogoutUrl: 'http%3A%2F%2Flocalhost%3A3000%2F',
      authRedirectLoginUrl: 'http%3A%2F%2Flocalhost%3A3000%2F',
      authRedirectUrl: 'http://localhost:3000/',
      authClientId: '5msi2bh65on2ouk6rbqdmcepss',
    },
  },
  dev: {
    apiBase: 'https://api.rbp.dev-gbmapi.com',
    apiBasePatron: 'https://backoffice.auth.dev-gbmapi.com',
    rolloutKey: '6079adf812147765a02a3ab1',
    torahDocumentation: 'https://torah.stg-gbmapi.com',
    configDefaultCognito: {
      ...configDefaultCognito,
      authUrl: 'https://patron-backoffice-stg-1596325.auth.us-east-1.amazoncognito.com',
      authRedirectLogoutUrl: 'https%3A%2F%2Frbp.dev-gbmapi.com%2F',
      authRedirectLoginUrl: 'https%3A%2F%2Frbp.dev-gbmapi.com%2F',
      authRedirectUrl: 'https://rbp.dev-gbmapi.com/',
      authClientId: '5msi2bh65on2ouk6rbqdmcepss',
    },
  },
  stg: {
    apiBase: 'https://api.rbp.stg-gbmapi.com',
    apiBasePatron: 'https://backoffice.auth.stg-gbmapi.com',
    rolloutKey: '6079ae18e954a7360cb1878f',
    torahDocumentation: 'https://torah.stg-gbmapi.com',
    configDefaultCognito: {
      ...configDefaultCognito,
      authUrl: 'https://patron-backoffice-stg-1596325.auth.us-east-1.amazoncognito.com',
      authRedirectLogoutUrl: 'https%3A%2F%2Frbp.stg-gbmapi.com%2F',
      authRedirectLoginUrl: 'https%3A%2F%2Frbp.stg-gbmapi.com%2F',
      authRedirectUrl: 'https://rbp.stg-gbmapi.com/',
      authClientId: '2fp823quu91180qub5u3bh2idq',
    },
  },
  prd: {
    apiBase: 'https://api.rbp.gbmapi.com',
    apiBasePatron: 'https://backoffice.auth.gbmapi.com',
    rolloutKey: '6079ad22a3c35327f3e07d98',
    torahDocumentation: 'https://torah.gbmapi.com',
    configDefaultCognito: {
      ...configDefaultCognito,
      authUrl: 'https://patron-backoffice-prd-1596325.auth.us-east-1.amazoncognito.com',
      authRedirectLogoutUrl: 'https%3A%2F%2Frbp.gbmapi.com%2F',
      authRedirectLoginUrl: 'https%3A%2F%2Frbp.gbmapi.com%2F',
      authRedirectUrl: 'https://rbp.gbmapi.com/',
      authClientId: '5j9o17vpavrgksjfonv5uumtll',
    },
  },
};

/* istanbul ignore next */
const createFrontendConfig = (appEnv = process.env.REACT_APP_ENV) => {
  const env = {
    /* istanbul ignore next */
    NODE_ENV: process.env.NODE_ENV || /* istanbul ignore next */ 'development',
    APP_ENV: getEnv(appEnv),
  };

  env.API_BASE = environments[env.APP_ENV].apiBase;
  env.TORAH_DOCUMENTATION = environments[env.APP_ENV].torahDocumentation;
  env.CONFIG_DEFAULT_COGNITO = environments[env.APP_ENV].configDefaultCognito;
  env.ROLLOUT_KEY = environments[env.APP_ENV].rolloutKey;

  env.API_BASE_PATRON = environments[env.APP_ENV].apiBasePatron;

  return env;
};

const getEnv = appEnv => {
  let environment = appEnv || 'LOCAL';

  /* istanbul ignore next */
  if (Object.keys(environments).indexOf(environment) < 0) {
    environment = 'LOCAL';
  }

  return environment;
};

export default createFrontendConfig;
